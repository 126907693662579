{
  "name": "rkc",
  "version": "1.153.1",
  "scripts": {
    "ng": "ng",
    "start": "nx serve",
    "start:custom": "nx run rkc:serve:custom",
    "build": "nx run rkc:build:production",
    "build:ci": "NX_REJECT_UNKNOWN_LOCAL_CACHE=0 nx run rkc:build:production",
    "build:custom": "nx run rkc:build:custom",
    "test": "cross-env TZ=UTC nx run-many --target=test --all",
    "jest": "cross-env TZ=UTC jest",
    "lint": "nx affected --target=lint",
    "lint:all": "nx run-many --target=lint --all",
    "test-ct:all": "cross-env TZ=UTC nx test rkc --parallel=false --test-path-pattern='^.+ct\\.spec\\.ts$'",
    "test-ci": "cross-env TZ=UTC nx affected --target=test --parallel=4 --runInBand --configuration=ci --test-path-ignore-patterns='^.+ct\\.spec\\.ts$'",
    "test-ci:all": "cross-env TZ=UTC nx run-many --target=test --parallel=4 --runInBand --configuration=ci --all --test-path-ignore-patterns='^.+ct\\.spec\\.ts$'",
    "audit-ci": "audit-ci --skip-dev -c --pass-enoaudit --report-type summary 2>&1 || exit 0",
    "format": "prettier --list-different \"**/*.{js,json,css,scss,less,md,ts,html,component.html}\"",
    "format:fix": "prettier --write \"**/*.{js,json,css,scss,less,md,ts,html,component.html}\"",
    "mock-api": "cross-os mock-api",
    "process-icons:material": "ts-node --project tsconfig.node.json -e \"import { processMaterialIcons } from './scripts/process-icons'; processMaterialIcons()\"",
    "process-icons:connectors": "ts-node --project tsconfig.node.json -e \"import { processConnectorIcons } from './scripts/process-icons'; processConnectorIcons()\"",
    "prepare": "husky"
  },
  "cross-os": {
    "mock-api": {
      "win32": "docker run -p 8280:8080 --rm --name ion -v \"%cd%/apps/rkc/e2e/mocks/mappings:/home/wiremock/mappings\" -v \"%cd%/apps/rkc/e2e/mocks/__files:/home/wiremock/__files\" -it wiremock/wiremock:3.1.0-alpine --verbose --enable-stub-cors --global-response-templating",
      "linux": "docker run -p 8280:8080 --rm --name ion -v \"$PWD/apps/rkc/e2e/mocks/mappings:/home/wiremock/mappings\" -v \"$PWD/apps/rkc/e2e/mocks/__files:/home/wiremock/__files\" -it wiremock/wiremock:3.1.0-alpine --verbose --enable-stub-cors --global-response-templating",
      "darwin": "docker run -p 8280:8080 --rm --name ion --platform linux/x86_64/v8 -v \"$PWD/apps/rkc/e2e/mocks/mappings:/home/wiremock/mappings\" -v \"$PWD/apps/rkc/e2e/mocks/__files:/home/wiremock/__files\" -it wiremock/wiremock:3.1.0-alpine --verbose --enable-stub-cors --global-response-templating"
    }
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.5",
    "@angular/cdk": "18.2.14",
    "@angular/common": "18.2.5",
    "@angular/compiler": "18.2.5",
    "@angular/core": "18.2.5",
    "@angular/forms": "18.2.5",
    "@angular/platform-browser": "18.2.5",
    "@angular/platform-browser-dynamic": "18.2.5",
    "@angular/router": "18.2.5",
    "@bluehalo/ngx-leaflet": "18.0.2",
    "@datadog/browser-logs": "6.0.0",
    "@datadog/browser-rum": "6.0.0",
    "@ionity/access-fee-service-v2-frontend-typings": "1.1.0",
    "@ionity/alerting-service-frontend-typings": "2.2.0",
    "@ionity/asset-information-service-frontend-typings": "7.12.0",
    "@ionity/audit-service-frontend-typings": "1.2.0",
    "@ionity/auth-service-frontend-typings": "1.1.0",
    "@ionity/bupa-frontend-typings": "5.0.1",
    "@ionity/bupa-v2-frontend-typings": "12.5.0",
    "@ionity/certificate-handler-frontend-typings": "1.0.1",
    "@ionity/charging-curve-frontend-typings": "0.2.0",
    "@ionity/charging-monitor-frontend-typings": "^1.6.0",
    "@ionity/charging-service-authorization-frontend-typings": "9.2.0",
    "@ionity/charging-session-management-frontend-typings": "16.0.0",
    "@ionity/cockpit-bff-frontend-typings": "31.0.0",
    "@ionity/controller-communication-gateway-frontend-typings": "2.0.0",
    "@ionity/data-quality-service-frontend-typings": "1.1.4",
    "@ionity/energy-management-service-frontend-typings": "1.2.0",
    "@ionity/ev-driver-frontend-typings": "1.53.0",
    "@ionity/fleet-management-service-frontend-typings": "0.18.0",
    "@ionity/logging-service-frontend-typings": "1.8.3",
    "@ionity/point-of-interest-frontend-typings": "6.3.0",
    "@ionity/pricing-ev-driver-frontend-typings": "5.4.0",
    "@ionity/pricing-frontend-typings": "15.1.0",
    "@ionity/products-service-frontend-typings": "5.6.0",
    "@ionity/queue-frontend-typings": "3.0.0",
    "@ionity/roaming-adapter-frontend-typings": "2.6.0",
    "@ionity/search-and-find-frontend-typings": "3.2.0",
    "@ionity/service-record-mediation-frontend-typings": "10.0.0",
    "@ionity/signeddata-provisioning-frontend-typings": "1.0.1",
    "@ionity/station-communication-gateway-frontend-typings": "1.0.0",
    "@ionity/station-operation-frontend-typings": "2.1.0",
    "@ionity/station-protocol-adapter-frontend-typings": "3.2.0",
    "@ionity/subscription-products-frontend-typings": "1.0.0",
    "@larscom/ngx-translate-module-loader": "^3.2.1",
    "@material-design-icons/svg": "^0.14.11",
    "@ng-web-apis/common": "^3.0.6",
    "@ng-web-apis/intersection-observer": "^3.2.0",
    "@ng-web-apis/mutation-observer": "^3.1.0",
    "@ng-web-apis/resize-observer": "^3.0.6",
    "@ngneat/bind-query-params": "^6.0.0",
    "@ngneat/elf": "^2.5.1",
    "@ngneat/elf-entities": "^5.0.2",
    "@ngneat/elf-persist-state": "^1.2.1",
    "@ngneat/elf-requests": "^1.9.2",
    "@ngx-translate/core": "15.0.0",
    "@ngx-translate/http-loader": "8.0.0",
    "@ostabo/flag-icons": "^8.0.0",
    "@swimlane/ngx-datatable": "~20.1.0",
    "@taiga-ui/addon-charts": "3.64.0",
    "@taiga-ui/addon-commerce": "3.64.0",
    "@taiga-ui/addon-doc": "3.64.0",
    "@taiga-ui/addon-mobile": "3.64.0",
    "@taiga-ui/addon-table": "3.64.0",
    "@taiga-ui/cdk": "3.64.0",
    "@taiga-ui/core": "3.64.0",
    "@taiga-ui/experimental": "3.64.0",
    "@taiga-ui/i18n": "3.64.0",
    "@taiga-ui/icons": "3.64.0",
    "@taiga-ui/kit": "3.64.0",
    "@taiga-ui/styles": "3.64.0",
    "@taiga-ui/testing": "3.64.0",
    "@tinkoff/ng-event-plugins": "^3.1.0",
    "@tinkoff/ng-polymorpheus": "4.3.0",
    "@tinyhttp/content-disposition": "2.2.2",
    "aws-amplify": "6.12.1",
    "codyframe": "^4.0.8",
    "cronstrue": "^2.51.0",
    "d3": "^7.9.0",
    "featurehub-javascript-client-sdk": "^1.3.2",
    "file-saver": "^2.0.5",
    "leaflet": "^1.9.4",
    "lodash-es": "^4.17.21",
    "luxon": "3.5.0",
    "ngx-highlightjs": "^12.0.0",
    "reflect-metadata": "^0.1.13",
    "rxjs": "7.8.1",
    "tslib": "^2.3.0",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.2.5",
    "@angular-devkit/core": "18.2.5",
    "@angular-devkit/schematics": "18.2.5",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cli": "18.2.5",
    "@angular/compiler-cli": "18.2.5",
    "@angular/language-service": "18.2.5",
    "@commitlint/cli": "19.6.1",
    "@commitlint/config-conventional": "19.6.0",
    "@ng-web-apis/universal": "^3.0.3",
    "@ngneat/aim": "2.0.0",
    "@nx/angular": "19.8.14",
    "@nx/eslint": "19.8.14",
    "@nx/eslint-plugin": "19.8.14",
    "@nx/jest": "19.8.14",
    "@nx/js": "19.8.14",
    "@nx/workspace": "19.8.14",
    "@pact-foundation/pact": "12.5.2",
    "@schematics/angular": "18.2.5",
    "@swc-node/register": "1.9.2",
    "@swc/core": "1.5.7",
    "@swc/helpers": "0.5.13",
    "@types/d3": "7.4.3",
    "@types/file-saver": "2.0.7",
    "@types/glob": "8.1.0",
    "@types/jest": "29.5.14",
    "@types/leaflet": "1.9.15",
    "@types/lodash-es": "4.17.12",
    "@types/luxon": "3.4.2",
    "@types/markdown-it": "14.1.2",
    "@types/node": "20.17.10",
    "@types/resize-observer-browser": "0.1.11",
    "@typescript-eslint/eslint-plugin": "7.18.0",
    "@typescript-eslint/parser": "7.18.0",
    "@typescript-eslint/utils": "7.18.0",
    "audit-ci": "7.1.0",
    "autoprefixer": "10.4.20",
    "cross-env": "7.0.3",
    "cross-os": "1.5.0",
    "eslint": "8.57.1",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-rxjs": "5.0.3",
    "husky": "9.1.7",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-environment-node": "29.7.0",
    "jest-pact": "0.11.1",
    "jest-preset-angular": "14.4.2",
    "jest-sonar": "0.2.16",
    "jsonc-eslint-parser": "2.4.0",
    "lint-staged": "15.4.1",
    "ng-packagr": "18.2.1",
    "ngx-translate-testing": "7.0.0",
    "nx": "19.8.14",
    "postcss": "8.4.49",
    "postcss-import": "16.1.0",
    "postcss-preset-env": "10.1.3",
    "postcss-url": "10.1.3",
    "prettier": "3.4.2",
    "raw-loader": "4.0.2",
    "ts-jest": "29.2.5",
    "ts-node": "10.9.2",
    "typescript": "5.5.4",
    "winston": "3.17.0"
  },
  "jestSonar": {
    "reportPath": "reports",
    "reportFile": "testresults-generic-sonar.xml"
  },
  "volta": {
    "node": "20.18.1"
  },
  "commitlint": {
    "extends": [
      "@commitlint/config-conventional"
    ]
  },
  "lint-staged": {
    "*.{js,json,css,scss,less,md,ts,html,component.html}": "prettier --write"
  }
}
