import { serializeConfiguration, ONE_KIBI_BYTE, validateAndBuildConfiguration, display, removeDuplicates, ConsoleApiName, RawReportType, objectValues } from '@datadog/browser-core';
/**
 * arbitrary value, byte precision not needed
 */
export const DEFAULT_REQUEST_ERROR_RESPONSE_LENGTH_LIMIT = 32 * ONE_KIBI_BYTE;
export function validateAndBuildLogsConfiguration(initConfiguration) {
  if (initConfiguration.usePciIntake === true && initConfiguration.site && initConfiguration.site !== 'datadoghq.com') {
    display.warn('PCI compliance for Logs is only available for Datadog organizations in the US1 site. Default intake will be used.');
  }
  const baseConfiguration = validateAndBuildConfiguration(initConfiguration);
  const forwardConsoleLogs = validateAndBuildForwardOption(initConfiguration.forwardConsoleLogs, objectValues(ConsoleApiName), 'Forward Console Logs');
  const forwardReports = validateAndBuildForwardOption(initConfiguration.forwardReports, objectValues(RawReportType), 'Forward Reports');
  if (!baseConfiguration || !forwardConsoleLogs || !forwardReports) {
    return;
  }
  if (initConfiguration.forwardErrorsToLogs && !forwardConsoleLogs.includes(ConsoleApiName.error)) {
    forwardConsoleLogs.push(ConsoleApiName.error);
  }
  return {
    forwardErrorsToLogs: initConfiguration.forwardErrorsToLogs !== false,
    forwardConsoleLogs,
    forwardReports,
    requestErrorResponseLengthLimit: DEFAULT_REQUEST_ERROR_RESPONSE_LENGTH_LIMIT,
    ...baseConfiguration
  };
}
export function validateAndBuildForwardOption(option, allowedValues, label) {
  if (option === undefined) {
    return [];
  }
  if (!(option === 'all' || Array.isArray(option) && option.every(api => allowedValues.includes(api)))) {
    display.error(`${label} should be "all" or an array with allowed values "${allowedValues.join('", "')}"`);
    return;
  }
  return option === 'all' ? allowedValues : removeDuplicates(option);
}
export function serializeLogsConfiguration(configuration) {
  const baseSerializedInitConfiguration = serializeConfiguration(configuration);
  return {
    forward_errors_to_logs: configuration.forwardErrorsToLogs,
    forward_console_logs: configuration.forwardConsoleLogs,
    forward_reports: configuration.forwardReports,
    use_pci_intake: configuration.usePciIntake,
    ...baseSerializedInitConfiguration
  };
}
