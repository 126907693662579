import { ErrorSource, combine, createEventRateLimiter, getRelativeTime, isEmptyObject } from '@datadog/browser-core';
import { STATUSES } from './logger';
import { getRUMInternalContext } from './contexts/rumInternalContext';
export function startLogsAssembly(sessionManager, configuration, lifeCycle, getCommonContext, reportError) {
  const statusWithCustom = STATUSES.concat(['custom']);
  const logRateLimiters = {};
  statusWithCustom.forEach(status => {
    logRateLimiters[status] = createEventRateLimiter(status, configuration.eventRateLimiterThreshold, reportError);
  });
  lifeCycle.subscribe(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, ({
    rawLogsEvent,
    messageContext = undefined,
    savedCommonContext = undefined,
    domainContext
  }) => {
    var _a, _b;
    const startTime = getRelativeTime(rawLogsEvent.date);
    const session = sessionManager.findTrackedSession(startTime);
    const shouldSendLog = sessionManager.findTrackedSession(startTime, {
      returnInactive: true
    });
    if (!shouldSendLog) {
      return;
    }
    const commonContext = savedCommonContext || getCommonContext();
    if (session && session.anonymousId && !commonContext.user.anonymous_id) {
      commonContext.user.anonymous_id = session.anonymousId;
    }
    const log = combine({
      service: configuration.service,
      session_id: session ? session.id : undefined,
      session: session ? {
        id: session.id
      } : undefined,
      // Insert user first to allow overrides from global context
      usr: !isEmptyObject(commonContext.user) ? commonContext.user : undefined,
      view: commonContext.view
    }, commonContext.context, getRUMInternalContext(startTime), rawLogsEvent, messageContext);
    if (((_a = configuration.beforeSend) === null || _a === void 0 ? void 0 : _a.call(configuration, log, domainContext)) === false || log.origin !== ErrorSource.AGENT && ((_b = logRateLimiters[log.status]) !== null && _b !== void 0 ? _b : logRateLimiters['custom']).isLimitReached()) {
      return;
    }
    lifeCycle.notify(1 /* LifeCycleEventType.LOG_COLLECTED */, log);
  });
}
