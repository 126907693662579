import { HttpClient } from '@angular/common/http';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';

export function moduleHttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';

  const options: IModuleTranslationOptions = {
    modules: [
      { baseTranslateUrl },
      { baseTranslateUrl, moduleName: 'easyfleet' },
      { baseTranslateUrl, moduleName: 'mon' },
      // add more entries for your libs here
    ],
    disableNamespace: true,
  };

  return new ModuleTranslateLoader(http, options);
}
