export function startInternalContext(sessionManager) {
  return {
    get: startTime => {
      const trackedSession = sessionManager.findTrackedSession(startTime);
      if (trackedSession) {
        return {
          session_id: trackedSession.id
        };
      }
    }
  };
}
