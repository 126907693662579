import { timeStampNow, ErrorSource, getFileFromStackTraceString, initReportObservable } from '@datadog/browser-core';
import { StatusType } from '../logger/isAuthorized';
import { createErrorFieldFromRawError } from '../createErrorFieldFromRawError';
export function startReportCollection(configuration, lifeCycle) {
  const reportSubscription = initReportObservable(configuration, configuration.forwardReports).subscribe(rawError => {
    let message = rawError.message;
    let error;
    const status = rawError.originalError.type === 'deprecation' ? StatusType.warn : StatusType.error;
    if (status === StatusType.error) {
      error = createErrorFieldFromRawError(rawError);
    } else if (rawError.stack) {
      message += ` Found in ${getFileFromStackTraceString(rawError.stack)}`;
    }
    lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, {
      rawLogsEvent: {
        date: timeStampNow(),
        message,
        origin: ErrorSource.REPORT,
        error,
        status
      }
    });
  });
  return {
    stop: () => {
      reportSubscription.unsubscribe();
    }
  };
}
