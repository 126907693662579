import { startTelemetry, canUseEventBridge, getEventBridge, startBatchWithReplica, createIdentityEncoder, isTelemetryReplicationAllowed, addTelemetryConfiguration, drainPreStartTelemetry } from '@datadog/browser-core';
import { getRUMInternalContext } from './contexts/rumInternalContext';
import { serializeLogsConfiguration } from './configuration';
export function startLogsTelemetry(initConfiguration, configuration, reportError, pageExitObservable, session) {
  const telemetry = startTelemetry("browser-logs-sdk" /* TelemetryService.LOGS */, configuration);
  telemetry.setContextProvider(() => {
    var _a, _b, _c, _d, _e, _f;
    return {
      application: {
        id: (_a = getRUMInternalContext()) === null || _a === void 0 ? void 0 : _a.application_id
      },
      session: {
        id: (_b = session.findTrackedSession()) === null || _b === void 0 ? void 0 : _b.id
      },
      view: {
        id: (_d = (_c = getRUMInternalContext()) === null || _c === void 0 ? void 0 : _c.view) === null || _d === void 0 ? void 0 : _d.id
      },
      action: {
        id: (_f = (_e = getRUMInternalContext()) === null || _e === void 0 ? void 0 : _e.user_action) === null || _f === void 0 ? void 0 : _f.id
      }
    };
  });
  const cleanupTasks = [];
  if (canUseEventBridge()) {
    const bridge = getEventBridge();
    const telemetrySubscription = telemetry.observable.subscribe(event => bridge.send('internal_telemetry', event));
    cleanupTasks.push(() => telemetrySubscription.unsubscribe());
  } else {
    const telemetryBatch = startBatchWithReplica(configuration, {
      endpoint: configuration.rumEndpointBuilder,
      encoder: createIdentityEncoder()
    }, configuration.replica && {
      endpoint: configuration.replica.rumEndpointBuilder,
      encoder: createIdentityEncoder()
    }, reportError, pageExitObservable, session.expireObservable);
    cleanupTasks.push(() => telemetryBatch.stop());
    const telemetrySubscription = telemetry.observable.subscribe(event => telemetryBatch.add(event, isTelemetryReplicationAllowed(configuration)));
    cleanupTasks.push(() => telemetrySubscription.unsubscribe());
  }
  drainPreStartTelemetry();
  addTelemetryConfiguration(serializeLogsConfiguration(initConfiguration));
  return {
    telemetry,
    stop: () => {
      cleanupTasks.forEach(task => task());
    }
  };
}
