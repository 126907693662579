/* istanbul ignore file */
import { Route, Routes } from '@angular/router';

import { ErrorComponent } from '@ion/error';
import { authenticationGuard } from '@ion/iam';

export const AppRoutes: Routes = [
  {
    path: 'pages',
    canActivate: [authenticationGuard],
    loadChildren: () => import('./pages/pages.routes').then(m => m.PagesRoutes),
  },
  { path: '', redirectTo: '/pages/dashboard', pathMatch: 'full' } as Route,
  { path: '403', component: ErrorComponent, data: { statusCode: 403 } },
  {
    path: '**',
    component: ErrorComponent,
    data: { statusCode: 404 },
  },
];
