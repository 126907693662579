export function isAuthorized(status, handlerType, logger) {
  const loggerHandler = logger.getHandler();
  const sanitizedHandlerType = Array.isArray(loggerHandler) ? loggerHandler : [loggerHandler];
  return STATUS_PRIORITIES[status] >= STATUS_PRIORITIES[logger.getLevel()] && sanitizedHandlerType.includes(handlerType);
}
export const StatusType = {
  ok: 'ok',
  debug: 'debug',
  info: 'info',
  notice: 'notice',
  warn: 'warn',
  error: 'error',
  critical: 'critical',
  alert: 'alert',
  emerg: 'emerg'
};
export const STATUS_PRIORITIES = {
  [StatusType.ok]: 0,
  [StatusType.debug]: 1,
  [StatusType.info]: 2,
  [StatusType.notice]: 4,
  [StatusType.warn]: 5,
  [StatusType.error]: 6,
  [StatusType.critical]: 7,
  [StatusType.alert]: 8,
  [StatusType.emerg]: 9
};
