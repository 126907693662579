import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumEvent, RumResourceEvent } from '@datadog/browser-rum';
import { environment } from '@ion/env';
import pkg from '../../../package.json';

export function initDatadog() {
  datadogRum.init({
    applicationId: '8b972e68-601f-4e13-8c76-2bd995b186c9',
    clientToken: 'pub1c560e54f31dae51ada7c0ca1b4763ac',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'red-kite-cockpit',
    // environment the front end is hosted on
    env: environment.environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: pkg.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [/https:\/\/rkc-gateway(.int|.stage){0,1}.ionity.cloud/],
    beforeSend: log => {
      if (isResourceLog(log)) {
        // This filters out all resource calls like loading fonts, js and icons
        // Also the calls to featurehub and our own assets are excluded, as they are spamming quite a bit
        return (
          log.resource.type === 'xhr' &&
          !(log.resource.url.includes('/featurehub') || log.resource.url.includes('/assets'))
        );
      }
      return true;
    },
  });

  datadogLogs.init({
    clientToken: 'pub1c560e54f31dae51ada7c0ca1b4763ac',
    site: 'datadoghq.eu',
    service: 'red-kite-cockpit',
    env: environment.environment,
    version: pkg.version,
    sessionSampleRate: 100,
    forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
  });
}

function isResourceLog(log: RumEvent): log is RumResourceEvent {
  return typeof log.resource === 'object';
}
