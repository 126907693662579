var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { clocksNow, computeRawError, computeStackTrace, combine, createContextManager, ErrorSource, monitored, sanitize, createHandlingStack, isError } from '@datadog/browser-core';
import { isAuthorized, StatusType } from './logger/isAuthorized';
import { createErrorFieldFromRawError } from './createErrorFieldFromRawError';
export const HandlerType = {
  console: 'console',
  http: 'http',
  silent: 'silent'
};
export const STATUSES = Object.keys(StatusType);
// note: it is safe to merge declarations as long as the methods are actually defined on the prototype
// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging, no-restricted-syntax
export class Logger {
  constructor(handleLogStrategy, customerDataTracker, name, handlerType = HandlerType.http, level = StatusType.debug, loggerContext = {}) {
    this.handleLogStrategy = handleLogStrategy;
    this.handlerType = handlerType;
    this.level = level;
    this.contextManager = createContextManager(customerDataTracker);
    this.contextManager.setContext(loggerContext);
    if (name) {
      this.contextManager.setContextProperty('logger', {
        name
      });
    }
  }
  logImplementation(message, messageContext, status = StatusType.info, error, handlingStack) {
    const sanitizedMessageContext = sanitize(messageContext);
    let context;
    if (error !== undefined && error !== null) {
      const rawError = computeRawError({
        stackTrace: isError(error) ? computeStackTrace(error) : undefined,
        originalError: error,
        nonErrorPrefix: "Provided" /* NonErrorPrefix.PROVIDED */,
        source: ErrorSource.LOGGER,
        handling: "handled" /* ErrorHandling.HANDLED */,
        startClocks: clocksNow()
      });
      context = combine({
        error: createErrorFieldFromRawError(rawError, {
          includeMessage: true
        })
      }, sanitizedMessageContext);
    } else {
      context = sanitizedMessageContext;
    }
    this.handleLogStrategy({
      message: sanitize(message),
      context,
      status
    }, this, handlingStack);
  }
  log(message, messageContext, status = StatusType.info, error) {
    let handlingStack;
    if (isAuthorized(status, HandlerType.http, this)) {
      handlingStack = createHandlingStack();
    }
    this.logImplementation(message, messageContext, status, error, handlingStack);
  }
  setContext(context) {
    this.contextManager.setContext(context);
  }
  getContext() {
    return this.contextManager.getContext();
  }
  setContextProperty(key, value) {
    this.contextManager.setContextProperty(key, value);
  }
  removeContextProperty(key) {
    this.contextManager.removeContextProperty(key);
  }
  clearContext() {
    this.contextManager.clearContext();
  }
  setHandler(handler) {
    this.handlerType = handler;
  }
  getHandler() {
    return this.handlerType;
  }
  setLevel(level) {
    this.level = level;
  }
  getLevel() {
    return this.level;
  }
}
__decorate([monitored], Logger.prototype, "logImplementation", null);
/* eslint-disable local-rules/disallow-side-effects */
Logger.prototype.ok = createLoggerMethod(StatusType.ok);
Logger.prototype.debug = createLoggerMethod(StatusType.debug);
Logger.prototype.info = createLoggerMethod(StatusType.info);
Logger.prototype.notice = createLoggerMethod(StatusType.notice);
Logger.prototype.warn = createLoggerMethod(StatusType.warn);
Logger.prototype.error = createLoggerMethod(StatusType.error);
Logger.prototype.critical = createLoggerMethod(StatusType.critical);
Logger.prototype.alert = createLoggerMethod(StatusType.alert);
Logger.prototype.emerg = createLoggerMethod(StatusType.emerg);
function createLoggerMethod(status) {
  return function (message, messageContext, error) {
    let handlingStack;
    if (isAuthorized(status, HandlerType.http, this)) {
      handlingStack = createHandlingStack();
    }
    this.logImplementation(message, messageContext, status, error, handlingStack);
  };
}
