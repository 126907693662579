import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@ion/env';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { initDatadog } from './datadog';

// Turn on Datadog on 'int', 'stage' and 'prod', and also on 'local' when forceEnableDatadog is set
if (environment.environment !== 'local' || environment.forceEnableDatadog) {
  initDatadog();
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
