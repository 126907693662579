import { combine, ErrorSource, timeStampNow, originalConsoleMethods, globalConsole, ConsoleApiName } from '@datadog/browser-core';
import { HandlerType } from '../logger';
import { isAuthorized, StatusType } from './isAuthorized';
export function startLoggerCollection(lifeCycle) {
  function handleLog(logsMessage, logger, handlingStack, savedCommonContext, savedDate) {
    const messageContext = combine(logger.getContext(), logsMessage.context);
    if (isAuthorized(logsMessage.status, HandlerType.console, logger)) {
      displayInConsole(logsMessage, messageContext);
    }
    if (isAuthorized(logsMessage.status, HandlerType.http, logger)) {
      const rawLogEventData = {
        rawLogsEvent: {
          date: savedDate || timeStampNow(),
          message: logsMessage.message,
          status: logsMessage.status,
          origin: ErrorSource.LOGGER
        },
        messageContext,
        savedCommonContext
      };
      if (handlingStack) {
        rawLogEventData.domainContext = {
          handlingStack
        };
      }
      lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, rawLogEventData);
    }
  }
  return {
    handleLog
  };
}
const loggerToConsoleApiName = {
  [StatusType.ok]: ConsoleApiName.debug,
  [StatusType.debug]: ConsoleApiName.debug,
  [StatusType.info]: ConsoleApiName.info,
  [StatusType.notice]: ConsoleApiName.info,
  [StatusType.warn]: ConsoleApiName.warn,
  [StatusType.error]: ConsoleApiName.error,
  [StatusType.critical]: ConsoleApiName.error,
  [StatusType.alert]: ConsoleApiName.error,
  [StatusType.emerg]: ConsoleApiName.error
};
function displayInConsole({
  status,
  message
}, messageContext) {
  originalConsoleMethods[loggerToConsoleApiName[status]].call(globalConsole, message, messageContext);
}
