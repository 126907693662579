import { Observable, performDraw, startSessionManager } from '@datadog/browser-core';
export const LOGS_SESSION_KEY = 'logs';
export function startLogsSessionManager(configuration, trackingConsentState) {
  const sessionManager = startSessionManager(configuration, LOGS_SESSION_KEY, rawTrackingType => computeSessionState(configuration, rawTrackingType), trackingConsentState);
  return {
    findTrackedSession: (startTime, options = {
      returnInactive: false
    }) => {
      const session = sessionManager.findSession(startTime, options);
      return session && session.trackingType === "1" /* LoggerTrackingType.TRACKED */ ? {
        id: session.id,
        anonymousId: session.anonymousId
      } : undefined;
    },
    expireObservable: sessionManager.expireObservable
  };
}
export function startLogsSessionManagerStub(configuration) {
  const isTracked = computeTrackingType(configuration) === "1" /* LoggerTrackingType.TRACKED */;
  const session = isTracked ? {} : undefined;
  return {
    findTrackedSession: () => session,
    expireObservable: new Observable()
  };
}
function computeTrackingType(configuration) {
  if (!performDraw(configuration.sessionSampleRate)) {
    return "0" /* LoggerTrackingType.NOT_TRACKED */;
  }
  return "1" /* LoggerTrackingType.TRACKED */;
}
function computeSessionState(configuration, rawSessionType) {
  const trackingType = hasValidLoggerSession(rawSessionType) ? rawSessionType : computeTrackingType(configuration);
  return {
    trackingType,
    isTracked: trackingType === "1" /* LoggerTrackingType.TRACKED */
  };
}
function hasValidLoggerSession(trackingType) {
  return trackingType === "0" /* LoggerTrackingType.NOT_TRACKED */ || trackingType === "1" /* LoggerTrackingType.TRACKED */;
}
